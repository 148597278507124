@import "../modules/login/modules";
@import "variables";
@import "common-login";

//Component modules
@include mod-login();
@include mod-user-login();
@include mod-login-otp-1();
@include mod-change-password();
@include mod-reset-password();
@include mod-login-otp();
@include mod-otp-verification-app();
@include mod-landing-page();

/*Tablet Landscape viewport*/
@media all and (min-width: $viewport-login-width-tablet-landscape + px) and (max-width: ($viewport-login-width-desktop - 1) + px){
    @include mod-login-tablet-landscape();
    @include mod-user-login-tablet-landscape();
    @include mod-login-otp-1-tablet-landscape();
    @include mod-change-password-tablet-landscape();
    @include mod-reset-password-tablet-landscape();
    @include mod-login-otp-tablet-landscape();
    @include mod-otp-verification-app-tablet-landscape();
    @include mod-landing-page-tablet-landscape();
}

/*Tablet Portrait viewport*/
@media all and (min-width: $viewport-login-width-tablet-portrait + px) and (max-width: ($viewport-login-width-tablet-landscape - 1) + px){
    @include mod-login-tablet-portrait();
    @include mod-user-login-tablet-portrait();  
    @include mod-login-otp-1-tablet-portrait();
    @include mod-change-password-tablet-portrait();
    @include mod-reset-password-tablet-portrait();
    @include mod-login-otp-tablet-portrait();
    @include mod-otp-verification-app-tablet-portrait();
    @include mod-landing-page-tablet-portrait();
}

/*Mobile viewport*/
@media all and (max-width: ($viewport-login-width-tablet-portrait - 1) + px){
    @include mod-login-mobile();
    @include mod-user-login-mobile();
    @include mod-login-otp-1-mobile();
    @include mod-change-password-mobile();
    @include mod-reset-password-mobile();
	@include mod-login-otp-mobile();
    @include mod-otp-verification-app-mobile();
    @include mod-landing-page-mobile();
}