@mixin mod-otp-verification-app(){
    //Start Module: OTP Verfication APP
    .otp-verification-app{
        @include display-flex();
        flex-direction: column;

        .page-title.page-title--login{
            margin: 0 0 65px;
        }

        a{
            &:focus,
            &:hover{
                color: #00b0ca;
            }
        }

        &--progress-timer{
            // otp-verification-app--progress-timer
            @include display-flex();
            justify-content: flex-end;
        }

        &--content{
            @include display-flex();
            flex-direction: column;
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: auto;
            margin-top: 20px;

            &--text {                  
                font-family: $font-primary-bold;
                @include rem-fallback(font-size, 18);
                letter-spacing: 1px;
            }   
        }

        &--footer{
            align-items: flex-end;
            @include display-flex();
            flex-direction: column;
            margin-bottom: 25px;

            &--notification{
                @include rem-fallback(font-size, 16);
                margin-bottom: 40px;
                color: #333333;
    
                &--link{
                    font-family: $font-primary-bold;
                    @include rem-fallback(font-size, 16);
                    color: #00b0ca;
                    padding-left: 8px;
                }
            }

            &--login{
                font-family: $font-primary-bold;
                @include rem-fallback(font-size, 16);
                color: #00b0ca;
            }
        }
    }
    //End Module: OTP Verfication APP
}

@mixin mod-otp-verification-app-tablet-landscape(){
    //Start Module: OTP Verfication APP - Tablet Landscape
    .otp-verification-app{

        .page-title.page-title--login{
            margin: 0 0 60px;
        }

        &--content{
            margin-top: 30px;
        }

        &--footer{
            margin-bottom: 30px;
        }

    }
    //End Module: OTP Verfication APP - Tablet Landscape
}

@mixin mod-otp-verification-app-tablet-portrait(){
    //Start Module: OTP Verfication APP - Tablet Portrait
    .otp-verification-app{
        .page-title.page-title--login{
            margin: 0 0 80px;
        }

        &--footer{
            margin-bottom: 30px;
        }
    }
    //End Module: OTP Verfication APP - Tablet Portrait
}

@mixin mod-otp-verification-app-mobile(){
    //Start Module: OTP Verfication APP - Mobile  
    .otp-verification-app{
        padding: 0 20px;
        
        .page-title.page-title--login{
            margin: 0 0 50px;
        }

        &--content{
            margin-top: 10px;
            &--text {
                @include rem-fallback(font-size, 16);
                @include rem-fallback(line-height, 20);
                letter-spacing: normal;
                // margin-bottom: 185px;
            }
        }

        &--footer{
            align-items: center;
            margin-bottom: 10px;

            &--notification{
                @include rem-fallback(font-size, 12);
                text-align: center;

                &--link{
                    @include rem-fallback(font-size, 12);
                }
            }

            &--login{
                // @include rem-fallback(font-size, 12);
                text-align: center;
            }
        }
    }
    //End Module: OTP Verfication APP - Mobile 
}