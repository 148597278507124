@import "fonts";
@import "../mixins/mixins";
@import "common";

body{
    min-width: auto !important;
}

a.login{
    color: #00b0ca;
}

.vodafone-error-msg{
    display: none;
}

.has-error{
    position: relative;
    input{
        border: solid 1px #e60000;
        background-color: rgba(230, 0, 0, 0.05); 
    }

    .vodafone-error-msg{
        align-items: center;
        background: url('../images/icon-error.svg') no-repeat left center;
        background-size: 15px 15px;
        color: #666666;
        @include display-flex();
        @include rem-fallback(font-size, 10px);
        height: 15px;
        margin: 10px 0;
        padding-left: 25px;
        position: absolute;
        width: 100%;
    }
}
/*Desktop viewport*/
@media all and (min-width: $viewport-login-width-desktop + px){
    .d-only			{display: block !important;}
    .tl-only		{display: none;}
    .tp-only		{display: none;}
    .m-only			{display: none;}
    
    .d-visible		{display: block !important;}
    .d-hidden		{display: none !important;}

    span.d-break	{display: block;}
    
    .d-align-center	{text-align: center};

    .d-wider-side-padding	{padding-left: 280px; padding-right: 280px;}

}

/*Tablet Landscape viewport*/
@media all and (min-width: $viewport-login-width-tablet-landscape + px) and (max-width: ($viewport-login-width-desktop - 1) + px){
    .d-only				{display: none;}
    .tl-only			{display: block !important;}
    .tp-only			{display: none;}
    .m-only				{display: none;}
    
    .tl-visible			{display: block !important;}
    .tl-hidden			{display: none !important;}

    span.tl-break		{display: block;}

    .tl-align-center	{text-align: center};

    .tl-wider-side-padding	{padding-left: 120px !important; padding-right: 120px !important;}
}

/*Tablet Portrait viewport*/
@media all and (min-width: $viewport-login-width-tablet-portrait + px) and (max-width: ($viewport-login-width-tablet-landscape - 1) + px){
    .d-only				{display: none;}
    .tl-only			{display: none;}
    .tp-only			{display: block !important;}
    .m-only				{display: none;}

    .tp-visible			{display: block !important;}
    .tp-hidden			{display: none !important;}

    span.tp-break		{display: block;}

    .tp-align-center	{text-align: center};
}

/*Mobile viewport*/
@media all and (max-width: ($viewport-login-width-tablet-portrait - 1) + px){
    .d-only			{display: none;}
    .tl-only		{display: none;}
    .tp-only		{display: none;}
    .m-only			{display: block !important;}

    .m-visible		{display: block !important;}
    .m-hidden		{display: none !important;}

    span.m-break	{display: block;}

    .m-align-center	{text-align: center};

}    