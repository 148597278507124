//Mixin for flex vendor prefixes
@mixin display-flex(){
    display: -webkit-flex;
    display: -ms-flex;
    display: -ms-flexbox;
    display: -moz-flex;
    display: flex;
}

@mixin display-flex-inline(){
    display: -webkit-inline-flex;
    display: -ms--inline-flex;
    display: -ms--inline-flexbox;
    display: -moz-inline-flex;
    display: inline-flex;
}