@mixin mod-user-login(){
    .user-login{
        // padding: 0 5 * $side-padding-desktop;
        display: flex;
        flex-direction: column;

        &--content{
            @include display-flex();
            flex-direction: column;
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: auto;

            &--wrapper{
                flex: 1 1 auto;
            }

            &--user-name{
                .lbl-vodafone{
                    margin-top: 0;
                }
                padding-bottom: 30px;
            }
        }
        
        &--footer{
            &--password-help{
                @include display-flex();
                flex-direction: column;
                align-items: flex-end;
                margin-top: 35px;
                margin-bottom: 30px;

                a{
                    color: #00b0ca;
                    font-family: $font-primary-bold;
                    @include rem-fallback(font-size, 14);
                    @include rem-fallback(line-height, 14);

                    &:not(:last-child){
                        margin-bottom: 5px;
                    }
                }
            }
        }
    }
}

@mixin mod-user-login-tablet-landscape(){
    .user-login{
        &--footer{
            &--password-help{
                margin: 15px 0 30px;
            }
        }
    }
}

@mixin mod-user-login-tablet-portrait(){
    .user-login{
        // margin-top: 50px;
        &--content{
            margin-top: 50px;
        }
        &--footer{
            &--password-help{
                margin: 15px 0 30px;
            }
        }
    }
}

@mixin mod-user-login-mobile(){
    .user-login{
        padding: 0 $side-padding-desktop;
        
        &--footer{
            &--password-help{
                align-items: center;
                flex-direction: column-reverse;
                margin: 20px 0 0;

                a{
                    margin-bottom: 10px;
                }
            }

            .btn--wrap{
                margin-top: 10px;
            }
        }
    }
}    
