@mixin mod-landing-page(){
    /*Start Module: Login - Landing Page*/
    .landing-page{    
        background-color: #ffffff;    
        &--header{
            background-color: #e60000;
            padding: 30px 110px;
            @include display-flex();
            justify-content: space-between;

            &--logo{
                align-items: flex-start;
                background-image: url('../images/icon-vodafone.svg');
                background-repeat: no-repeat;
                width: 170px;
                height: 45px;
            }

            &--profile{
                align-self: center;
                @include display-flex();

                &--text{
                    font-family: $font-primary-bold;
                    @include rem-fallback(font-size, 22);               
                    color: #ffffff;
                    margin-top: 2px;
                }

                &--icon{
                    width: 30px;
                    height: 30px;
                    background-image: url('../images/icon-profile.svg');
                    background-size: 30px 30px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    margin-left: 15px;
                }
            }            
       }

       &--content{
           padding: 120px 110px;

            &--heading{     
                @include display-flex();
                justify-content: space-between;
                padding-bottom: 50px;

                &--title{
                    color: #333333;
                    font-family: $font-primary-bold;
                    @include rem-fallback(font-size, 42); 
                    letter-spacing: 1.3px;
                }

                &--search{
                    width: 30px;
                    height: 30px;
                    background-image: url('../images/icon-search.svg');
                    background-size: 30px 30px;
                    background-repeat: no-repeat;
                    align-self: center;
                }
            }

            &--cards{
                margin : 0;
                
                &--team{
                    padding: 30px; 
                    // width: 368px;
                    width: 32.1%;
                    min-height: 340px;
                    display: inline-block;
                    border-radius: 5px;
                    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
                    margin: 0 20px 20px 0;

                    &:nth-child(3n){
                        margin-right: 0;
                    }

                    &--icon{
                        width: 30px;
                        height: 30px;
                        display: inline-block;
                        background-image: url('../images/icon-team.svg');
                        background-size: 30px 30px;
                        background-repeat: no-repeat;
                        margin-bottom: 40px;
                    }

                    &--name{
                        color: #333333; 
                        font-family: $font-primary-bold;
                        @include rem-fallback(font-size, 22); 
                        letter-spacing: 1.6px;
                        margin-top: -4px;
                        padding-bottom: 5px;
                    }

                    &--title{
                        color: #666666;
                        @include rem-fallback(font-size, 16);
                        letter-spacing: 1.1px;
                        padding-bottom: 30px;
                    }

                    &--description{
                        @include rem-fallback(font-size, 14);
                        letter-spacing: 1.1px;
                        padding-bottom: 50px;
                    }

                    .btn--wrap{
                        margin-top: 0;
                        text-transform: uppercase;

                        .btn.login-btn{  
                            min-width: 100px;
                        }  
                    }
                }
            }
       }
    }

    body{
        &.modal-open{
            .landing-page{
                &--header{
                    &--profile{
                        &::after{
                            background-image: url('../images/icon-white-user.svg');
                        }
                    }
                }
            }
        }
    }
    /*End Module: Login -  Landing Page*/
}

@mixin mod-landing-page-tablet-landscape(){
    /*Start Module: Login -  Landing Page - Tablet-landscape*/
    .landing-page{  
        &--header{
            padding: 30px 134px;

            &--logo{
                width: 115px;
                height: 30px;
                background-size: 115px 30px;
            }

            &--profile{                
                &--text{
                    display: none;
                }
            }
        }    

        &--content{
            padding: 50px 134px;

            &--heading{
                padding-bottom: 30px;
                &--title{
                    @include rem-fallback(font-size, 30); 
                    @include rem-fallback(line-height, 30); 
                    letter-spacing: 0.9px;
                }

                &--search{
                    width: 20px;
                    height: 20px;
                    background-size: 20px 20px; 
                }
            }

            &--cards{
                &--team{
                    width: 48.67%;

                    &:nth-child(3n){
                        margin-right: 20px;
                    }

                    &:nth-child(2n){
                        margin-right: 0;
                    }    
                }
            }
        }
    }
    /*End Module: Login - Landing Page - Tablet-landscape*/
}

@mixin mod-landing-page-tablet-portrait(){
    /*Start Module: Login - Landing Page - Tablet-portrait*/
    .landing-page{    
        &--header{
            padding: 30px 50px;

            &--logo{
                width: 115px;
                height: 30px;
                background-size: 115px 30px;
            }

            &--profile{
                &--text{
                    display: none;
                }
            }
        }

        &--content{
            padding: 50px;
            
            &--heading{
                padding-bottom: 30px;
                &--title{
                    @include rem-fallback(font-size, 30); 
                    @include rem-fallback(line-height, 30); 
                    letter-spacing: 0.9px;
                }

                &--search{
                    width: 20px;
                    height: 20px;
                    background-size: 20px 20px; 
                }
            }

            &--cards{
                &--team{
                    // width: 322px;
                    width: 48.5%;
                    min-height: 322px;

                    &--icon{
                        margin-bottom: 30px;
                    }

                    &--name{
                        @include rem-fallback(line-height, 22); 
                        letter-spacing: 1px;
                    }

                    &--title{
                        @include rem-fallback(line-height, 16); 
                        letter-spacing: 1px;
                    }

                    &--description{
                        @include rem-fallback(line-height, 18); 
                        letter-spacing: 1px;
                        padding-bottom: 25px;
                    }

                    &:nth-child(3n){
                        margin-right: 20px;
                    }
    
                    &:nth-child(2n){
                        margin-right: 0;
                    }    
                }
            }
        }    
    }
    /*End Module: Login - Landing Page - Tablet-portrait*/
}

@mixin mod-landing-page-mobile(){
    /*Start Module: Login - Landing Page - Mobile*/
    .landing-page{        
        &--header{
            padding: 15px 20px;

            &--logo{
                width: 90px;
                height: 25px;
                background-size: 90px 25px;
            }

            &--profile{
                
                &--text{
                    display: none;
                }

                &--icon{
                    width: 20px;
                    height: 20px;
                    background-size: 20px 20px; 
                }
            }
        }

        &--content{
            padding: 30px 20px;

            &--heading{
                padding-bottom: 15px;

                &--title{
                    @include rem-fallback(font-size, 30); 
                    @include rem-fallback(line-height, 30); 
                    letter-spacing: 0.9px;
                }

                &--search{
                    width: 20px;
                    height: 20px;
                    background-size: 20px 20px; 
                }
            }

            &--cards{
                &--team{
                    padding: 30px 20px;
                    // width: 280px;
                    width: 100%;
                    min-height: 288px;
                    margin: 0 0 20px;

                    &--icon{
                        margin-bottom: 20px;
                    }

                    &--name{
                        @include rem-fallback(font-size, 20); 
                        @include rem-fallback(line-height, 20); 
                        letter-spacing: 1px;
                    }

                    &--title{
                        @include rem-fallback(font-size, 14); 
                        @include rem-fallback(line-height, 14); 
                        letter-spacing: 1px;
                    }

                    &--description{
                        @include rem-fallback(font-size, 12); 
                        @include rem-fallback(line-height, 16); 
                        letter-spacing: 1px;
                        padding-bottom: 30px;
                    }

                    .btn--wrap{
                        .btn.login-btn{
                            padding: 10px 20px;
                        }
                    }
                }
            }
        }
    }
    /*End Module: Login - Landing Page - Mobile*/
}