@mixin mod-reset-password(){
    //Start Module: Reset password
    .reset-passsword{
        // display: flex;
        @include display-flex();
        flex-direction: column;
        
        .login-description{
            @include display-flex();
            flex-direction: column;
            flex: 1 1 auto;
            // margin: 0 0 25px;
        
            &--content{
                flex-grow: 1;
                flex-shrink: 1;
                flex-basis: auto;
            }
            &--btn-wrapper{
                // margin-bottom: 50px;            
            }                                     
        }
    }
}

@mixin mod-reset-password-tablet-landscape(){
    .reset-passsword{
        .login-description{
             margin: 50px 0 0;

            .desktop-h{
                display:block;
            }
        }
    }       
}
@mixin mod-reset-password-tablet-portrait(){
    .reset-passsword{
        .login-description{       
            margin: 50px 0 0;                     
        }
    }
    
}

@mixin mod-reset-password-mobile(){
    .reset-passsword{
        padding:0 20px;
        .login-description{
                        
        }
    }      
}    
