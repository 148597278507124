@font-face {
    font-family: 'vodafone_rgbold';
    src: url('../fonts/vodafonergbd-webfont.eot');
    src: url('../fonts/vodafonergbd-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/vodafonergbd-webfont.woff2') format('woff2'),
         url('../fonts/vodafonergbd-webfont.woff') format('woff'),
         url('../fonts/vodafonergbd-webfont.ttf') format('truetype'),
         url('../fonts/vodafonergbd-webfont.svg#vodafone_rgbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'vodafone_rgregular';
    src: url('../fonts/vodafonerg-webfont.eot');
    src: url('../fonts/vodafonerg-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/vodafonerg-webfont.woff2') format('woff2'),
         url('../fonts/vodafonerg-webfont.woff') format('woff'),
         url('../fonts/vodafonerg-webfont.ttf') format('truetype'),
         url('../fonts/vodafonerg-webfont.svg#vodafone_rgregular') format('svg');
    font-weight: normal;
    font-style: normal;
}