@import "fonts";
@import "../mixins/mixins";

html, body{
    font-size: $root-font-size;
    font-family: $font-primary;
    color: $text-color-dark;
    line-height: normal;
    min-height: 100%;
}

body{
    min-height: 100vh;
    // overflow-x: hidden;
    background-color: #f4f4f4;
    min-width: 768px;
}

h1, h2, h3, h3, h4, h5, h6{
    margin: 0;
    padding: 0;
}

ul{
    margin: 0;
    padding: 0;
}

ul li{
    color: inherit;
    text-decoration: none;
    list-style: none;
}

a{
    text-decoration: none;
    color: inherit;

    &:hover,
    &:focus{
        text-decoration: none;
        color: inherit;
    }
}

.fade{
    opacity: 0;
    transition: opacity $transition-duration $transition-timing-function;
}

.hidden{
    display: none !important;
}

.invisible{
    visibility: hidden !important;
}

.no-margin{
    margin: 0 !important;
}

.no-margin-top{
    margin-top: 0 !important;
}

.no-margin-right{
    margin-right: 0 !important;
}

.no-margin-bottom{
    margin-bottom: 0 !important;
}

.no-margin-left{
    margin-left: 0 !important;
}

.no-padding{
    padding: 0 !important;
}

.no-padding-top{
    padding-top: 0 !important;
}

.no-padding-right{
    padding-right: 0 !important;
}

.no-padding-bottom{
    padding-bottom: 0 !important;
}

.no-padding-left{
    padding-left: 0 !important;
}

.text-align-left{
    text-align: left !important;
}

.text-align-center{
    text-align: center !important;
}

.text-align-right{
    text-align: right !important;
}

.noscript{
    padding: 30px;
    text-align: center;
}

.no-wrap{
    white-space: nowrap;
}

.highlight{
    color: $theme-color-primary;
}

.flex-fill-max{
    flex: 1;
}

.flex-fill-least{
    flex: 0;
}

.section-container{
    position: relative;
    margin: 0 auto;
    padding-left: $side-padding-desktop;
    padding-right: $side-padding-desktop;

    &--fixed{
        max-width: $app-max-width;
    }

    &--fluid{
        width: 100%;
    }
}

.main-container{
    padding-top: 4 * $side-padding-desktop;
    padding-bottom: 2 * $side-padding-desktop;
}

/*Desktop viewport*/
@media all and (min-width: $viewport-width-desktop + px){
    body{
        &.scroll-disabled{
            overflow: hidden;
        }
    }

    .d-only			{display: block !important;}
    .t-only			{display: none;}
    
    .d-visible		{display: block !important;}
    .d-hidden		{display: none !important;}

    span.d-break	{display: block;}
    
    .d-align-center	{text-align: center};
}

/*Tablet viewport*/
@media all and (max-width: ($viewport-width-desktop - 1) + px){
    .d-only			{display: none;}
    .t-only			{display: block !important;}

    .t-visible		{display: block !important;}
    .t-hidden		{display: none !important;}

    span.t-break	{display: block;}

    .t-align-center	{text-align: center};

    .main-container{
        padding-top: 2.5 * $side-padding-desktop;
    }
}