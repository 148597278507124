@mixin mod-login-otp-1(){
    /*Start Module: Login - OTP - 1*/
    .login-otp1{
        @include display-flex();
        flex-direction: column;
        // width: 460px;

        &--link{
            background-image: url('../images/icon-right-arrow.svg');
            background-repeat: no-repeat;            
            background-position: center right 20px;
            box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.07);        
            font-family: $font-primary-bold;
            @include rem-fallback(font-size, 18);
            letter-spacing: 1.3px;
            padding: 25px;
            display: flex;
            align-items: center;
            min-width: 460px;

            &:not(:last-child){                
                margin-bottom: 40px;
            }

            &--icon {
                // display: inline-block; 
                height: 70px;
                width: 70px;

                &--app{
                    background-image: url('../images/icon-app.png');
                }

                &--code{
                    background-image: url('../images/icon-code.png');
                }

                &--app,&--code{
                    background-repeat: no-repeat;
                    background-position: 20px 10px;
                }
            }

            &--text{
                padding-left: 20px;
                // padding-left: $side-padding-desktop;
            }
        }

        .page-title.page-title--login{
            margin-bottom: 95px;
        }
    }

    /*End Module: Login - OTP - 1*/
}

@mixin mod-login-otp-1-tablet-landscape(){
    /*Start Module: Login - OTP - 1 - Tablet-landscape*/
    .login-otp1{
        margin-bottom: -25px;
        
        .page-title.page-title--login{
            margin-bottom: 90px;
        }
    }
    /*End Module: Login - OTP - 1 - Tablet-landscape*/
}

@mixin mod-login-otp-1-tablet-portrait(){
    /*Start Module: Login - OTP - 1 - Tablet-portrait*/
    .login-otp1{
        .page-title.page-title--login{
            margin-bottom: 90px;
        }
    }
    /*End Module: Login - OTP - 1 - Tablet-portrait*/
}

@mixin mod-login-otp-1-mobile(){
    /*Start Module: Login - OTP - 1 - Mobile*/
    .login-otp1{
        padding: 0 20px;

        &--link{
            min-width: 280px;
            background: none;
            flex-direction: column;
            padding: 10px 0 20px;

            &:not(:last-child){ 
                margin-bottom: 30px;
            }

            &--text{
                @include rem-fallback(font-size, 16);
                @include rem-fallback(line-height, 16);
                letter-spacing: 1.14px;
                padding-left: 0;
                margin-top: 4px;
            }
        }

        .page-title.page-title--login{
            margin-bottom: 50px;
        }
    }
    /*End Module: Login - OTP - 1 - Mobile*/
}