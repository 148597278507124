@mixin mod-change-password(){
    /*Start Module: Login - change-password*/
    .change-password{        
        // width: 460px;
        @include display-flex();
        flex-direction: column;

        &--form{
            @include display-flex();
            flex-direction: column;
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: auto;

            &--input{
                margin-bottom: 30px;

                &:last-child{
                    margin-bottom: 0;
                }
            }

            &--wrapper{
                // @include display-flex();
                // flex-direction: column;
                flex-grow: 1;
                flex-shrink: 1;
                flex-basis: auto;
            }

            .btn{
                text-transform: uppercase;
            }

            .btn--wrap{
                .btn:not(:last-child){
                    margin-right: 0;
                }
            }

            // &--continue{
            //     // margin-top: 20px;
            //     margin-right: 0px !important;
            // }            
        }

        .page-title.page-title--login{
            margin-bottom: 40px;
        }  
    }
    /*End Module: Login - change-password*/
}

@mixin mod-change-password-tablet-landscape(){
    /*Start Module: Login - change - password- Tablet-landscape*/
    .change-password{  
        .page-title.page-title--login{
            margin-bottom: 20px;
        }  
    }
    /*End Module: Login - change - password - Tablet-landscape*/
}

@mixin mod-change-password-tablet-portrait(){
    /*Start Module: Login - change - password - Tablet-portrait*/
    .change-password{   
    }
    /*End Module: Login - change - password - Tablet-portrait*/
}

@mixin mod-change-password-mobile(){
    /*Start Module: Login - change - password - Mobile*/
    .change-password{
        padding: 0 20px;

        .page-title.page-title--login{
            margin-bottom: 20px;
        } 
    }
    /*End Module: Login - change - password - Mobile*/
}