@mixin mod-login(){
    /*Start Module: Login*/
    .login{
        width: 100%;
        // min-height: 100vh;

        &--wrapper{
            @include display-flex();
            min-height: 100vh;
        }

        &--content{
            &--right{
                background: $theme-color-primary url('../images/icon-logo-without-text.svg') no-repeat center center;
                background-size: 120px 120px;
                flex-grow: 1;
                flex-shrink: 1;
                flex-basis: 0; 
                min-height: 100vh;
                position: relative;


                .top-left,.top-right,.bottom-left,.bottom-right{
                    display: inline-block;
                    position: absolute;
                    background-repeat: no-repeat;
                }

                .top-left{
                    width: 174px;
                    height: 134px;
                    background-image: url('../images/icon-top-left.png');
                    background-size: 174px 134px;
                }

                .top-right{
                    width: 165px;
                    height: 115px;
                    background-image: url('../images/icon-top-right.png');
                    background-size: 165px 115px;
                    right:0;
                    top:0;
                }

                .bottom-left{
                    width: 195px;
                    height: 226px;
                    background-image: url('../images/icon-bottom-left.png');
                    background-size: 195px 226px;
                    left:0;
                    bottom:0;
                }

                .bottom-right{
                    width: 242px;
                    height: 318px;
                    background-image: url('../images/icon-bottom-right.png');
                    background-size: 242px 318px;
                    right:0;
                    bottom:0;
                }
            }
            &--left{
                background-color: white;
                @include display-flex();
                justify-content: center;
                flex-grow: 1;
                flex-shrink: 1;
                flex-basis: 0;
                min-height: 100vh;
                padding: 100px 0 140px;     
            }
        }
    }
    /*End Module: Login*/
}

@mixin mod-login-tablet-landscape(){
    /*Start Module: Login - Tablet Landscape*/
    .login{
        &--wrapper{
            // flex-direction: column;
        }
        &--content{
            &--right{
                background-size: 80px 80px;
                flex-grow: 1.3;

                .top-left,.bottom-left{
                    display: none;
                }      
                
                .top-right{
                    height: 121px;
                    background-size: 165px 121px;
                }
            }

            &--left{
                flex-grow: 2.5;
                padding: 50px 0 140px;
            }
        }
    }
    /*End Module: Login - Tablet Landscape*/    
}

@mixin mod-login-tablet-portrait(){
    /*Start Module: Login - Tablet Portrait*/
    .login{
        &--wrapper{
            flex-direction: column;
        }
        &--content{
            &--right{
                height: 350px;
                background-size: 80px 80px;
                flex-grow: 0;
                flex-shrink: 1;
                flex-basis: auto;
                height: 350px;
                min-height: auto;

                .top-left,.top-right{
                    display: none;
                }

                .bottom-right{
                    width: 224px;
                    background-size: 224px 318px;
                }
            }

            &--left{
                flex-basis: auto;
                height: auto;
                min-height: auto;
                padding: 60px 0 80px;
            }
        }
    }
    /*End Module: Login - Tablet Portrait*/    
}



@mixin mod-login-mobile(){
    /*Start Module: Login - Mobile*/
    .login{
        &--wrapper{

        }
        &--content{
            &--right{
                display: none;
            }

            &--left{
                justify-content: normal;
                padding: 10px 0 20px;
            }
        }
    }
    /*End Module: Login - Mobile*/
}    
