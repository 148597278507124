//Mixin for REM fallback
@function strip-unit($num){
	@return $num / ($num * 0 + 1);
}

@mixin rem-fallback($property, $values...){
	$max: length($values);
	$pxValues: '';
	$remValues: '';

	@for $i from 1 through $max{
		$value: strip-unit(nth($values, $i));
		$pxValues: #{$pxValues + $value}px;
		@if $i < $max{
			$pxValues: #{$pxValues + " "};
		}
	}

	@for $i from 1 through $max{
		$value: strip-unit(nth($values, $i));
		$remValues: #{$remValues + $value / strip-unit($root-font-size)}rem;
		@if $i < $max{
			$remValues: #{$remValues + " "};
		}
	}

	#{$property}: $pxValues;
	#{$property}: $remValues;
}