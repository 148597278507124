//Fonts
$font-primary:              'vodafone_rgregular', Arial, sans-serif;
$font-primary-bold:         'vodafone_rgbold', $font-primary;
$root-font-size: 16px;
$root-font-size-sm: $root-font-size - 2px;
$root-font-size-lg: $root-font-size + 3px;

//Sizes
$app-max-width: 1280px;
$app-min-width: 768px;
$side-padding-desktop: 20px;
$side-padding-tablet: 20px;

//Colors
$theme-color-primary:   #e60000;
$theme-color-secondary:   #990000;
$text-color-dark:       black;
// $text-color-light:      #555555;

//Misc
$transition-timing-function: ease-in-out;
$transition-duration: 300ms;

//Viewport settings
$viewport-width-desktop: 992;
//992 px based on Bootstrap grid system

//Viewport settings for Login Module
$viewport-login-width-tablet-portrait: 768;
$viewport-login-width-tablet-landscape: 1024;
$viewport-login-width-desktop: 1280;