@mixin mod-login-otp(){
    //Start Module: Login Otp
    .login-otp{
        @include display-flex();
        flex-direction: column;

        &--lable-desc{
                @include display-flex();
                flex-direction: column;
                flex-grow: 1;
                flex-shrink: 1;
                flex-basis: auto;

            &--wrapper{
                flex-grow: 1;
                flex-shrink: 1;
                flex-basis: auto;
            }
            &--otp-progress {
                @include display-flex();
                justify-content: flex-end;

                .countDown{
                    @include rem-fallback(font-size, 16);
                    @include rem-fallback(line-height, 16);
                    margin-left: 20px;
                    color: #666666;
                    font-family: $font-primary-bold;
                    
                }
            &--label {
                @include display-flex();
                flex-direction: row;
                flex:1;

                &--uname {
                    @include display-flex();
                    flex-direction: column;
                    flex: 1;
                }
                
                
                }
            }
            &--footer{ 
                .btn-left.backto-login {
                    padding: 10px;
                }               
                a.right-text{
                        color: #00b0ca;
                        font-family: $font-primary-bold;
                        @include rem-fallback(font-size, 16);
                        @include rem-fallback(line-height, 19);                            
                    }
                }
            }
            
        }
    
    //End Module: Login Otp
}

@mixin mod-login-otp-tablet-landscape(){
    /*Start Module: login-opt Tablet-landscape*/
    .login-otp{

    }
    /*End Module: login-opt Tablet-landscape*/          
}
@mixin mod-login-otp-tablet-portrait(){
    /*Start Module: login-opt Tablet-portrait*/
    .login-otp{

    }
    /*End Module: login-opt Tablet-portrait*/    
}

@mixin mod-login-otp-mobile(){
    /*Start Module: login-opt Mobile*/
    .login-otp{
        padding: 20px;
        &--lable-desc{
            &--otp-progress {
                @include display-flex();
                justify-content: flex-end;
                margin-bottom: -10px;
            }
            .frm-vodafone--row.btn--wrap {
                display: flex;
                flex-direction: c;
                flex-direction: column;
                justify-content: center;
            }
            .btn-left.backto-login {
                padding: 20px;
                text-align: center;
            }

        }
    }
    /*End Module: login-opt Mobile*/
         
}    